<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
      sm="12"
      class="pt-0"
    >
      <session-title
        class="pt-8 px-10 pb-5"
        title="Fotos"
      />
      <photos-cards />
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from '@/components/SessionTitle'
import PhotosCards from '../../components/PhotosCards'

export default {
  name: 'Photo',
  components: {
    PhotosCards,
    'session-title': SessionTitle
  }
}
</script>
