<template>
  <v-row
    align="center"
    class="px-12"
  >
    <v-col
      v-for="(item, i) in imgs"
      :key="item.id"
      cols="12"
      md="3"
      sm="6"
      @click="() => showImg(i)"
    >
      <v-img
        class="white--text align-end"
        height="300px"
        :src="item.link"
      />
    </v-col>
    <vue-easy-lightbox
      esc-disabled
      move-disabled
      :visible="visible"
      :imgs="imageLightbox"
      :index="index"
      @hide="handleHide"
    />
  </v-row>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { findBySlug } from '../services/photo-service'
export default {
  name: 'PhotosCards',
  components: {
    VueEasyLightbox
  },
  data: () => ({
    imgs: [],
    imageLightbox: [],
    visible: false,
    index: 0
  }),
  mounted () {
    this.initList()
  },
  methods: {
    async initList () {
      try {
        const data = await findBySlug(this.$route.params.slug)
        this.imgs = data
        data.map((urls) => {
          this.imageLightbox.push(urls.link)
        })
      } catch (e) {
        this.$handleHttpError(e)
      }
    },
    showImg (index) {
      this.index = index
      this.visible = true
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    }
  }
}
</script>
